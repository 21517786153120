<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{ title }}</h4>
                </div>
                <div class="card-body">
                    <contract-form :contract="contract">
                        <template #buttons="{save, btnClass}">
                            <callback-button
                                class="ms-2"
                                :class="btnClass"
                                :method="save"
                                :callback="back"
                            ></callback-button>
                        </template>
                    </contract-form>
                </div>
            </div>
        </div>
        <div v-if="true === false" class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{ $t('projects::contracts.relationships.billables').ucFirst() }}</h4>
                </div>
                <div class="card-body bg-light">
                    <div class="row" v-if="contract.id">
                        <div class="col-lg-12">
                            <billable-items :id="contract.id" field="contract_id" store="billableContract"></billable-items>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- end row -->
</template>

<script>

import {mapGetters} from "vuex";
import CallbackButton from "../../../components/elements/callback-button.vue";
import ContractForm from "../../../components/forms/contract-form.vue";
import BillableItems from "../../../components/elements/billable-items.vue";

export default {
    props: {
        own: {
            type: Boolean,
            default: false
        }
    },

    components: {BillableItems, ContractForm, CallbackButton},

    computed: {
        ...mapGetters({
            contract: 'contract/item'
        }),

        contractTitle: function () {
            return (this.contract.translation_key ? this.$t(this.contract.translation_key).ucFirst() :this.contract.title) || '';
        },

        title: function () {
            return this.contract.id ? this.$tc('projects::contracts.contract', 2).ucFirst() + (this.contractTitle ? ' / ' : '') + this.contractTitle : this.$t('base.create_new_item', {item: this.$tc('projects::contracts.contract', 1)}).ucFirst()
        },

        link: function () {
            return {path: this.$t('routes.' + '/contracts')}
        }
    },

    methods: {
        back: function () {
            this.$router.go(-1);
            // this.$router.push(this.link)
        },
    },

    watch: {
        title: function (value) {
            this.$store.dispatch('app/title', {key: value})
        },
    },

    mounted() {
        if (this.$route.params.id) {
            this.$store.dispatch('contract/show', {id: this.$route.params.id, query: {with: ['project', 'offers', 'billables']}})
        }
        this.$store.dispatch('project/own')
    },

    unmounted() {
        this.$store.dispatch('contract/clearItem')
        this.$store.dispatch('app/title', '')
    }

}
</script>

<style scoped>

</style>
